import React, { useState, useEffect, useCallback } from 'react';
import { TToolbarInteraction } from '@fluentui/react-teams/lib/esm/components/Toolbar/Toolbar';
import {
  ProviderConsumer,
  Flex,
  Button,
  AddIcon,
  SyncIcon
} from '@fluentui/react-northstar';
import { Toolbar } from '../shared/components/Toolbar';
import { useHistory } from 'react-router-dom';
import EmptySVG from './../../svg/empty.svg';
import { EmptyData } from '../shared/components/EmptyData';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import { toTitleCase } from '../shared/utils/helper';
import {
  AUTOMATION,
  GROUPS,
  SETTINGS,
  TAGS,
  UPDATE,
  USERS,
  TEMPLATES,
  ACCESS_TOKEN,
  SLA,
  TEAMS,
  PRODUCT_LICENSE
} from '../shared/utils/constants';
import { TableWrapper } from '../shared/components/table/TableWrapper';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { toolbarButtonColor } from '../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  addNewTitle?: string;
  routeName: string;
  breadcrumbs: Breadcrumbs;
  ifEmpty?: IfEmptyProps;
  headers: string[];
  rows: RowsData[];
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onRowClickHandler?: (id: number) => void;
  onAddHandler?: () => void;
  syncAllUsers?: () => void;
  headerNotTitleCase?: boolean;
  height?: string;
  searchText?: string;
  onSearchKeyChanged?: (data: string) => void;
}

export const KBListWrapper = ({
  title,
  addNewTitle,
  routeName,
  breadcrumbs,
  ifEmpty,
  headers,
  rows,
  currentPage,
  setCurrentPage,
  syncAllUsers,
  onRowClickHandler,
  onAddHandler,
  headerNotTitleCase = false,
  height,
  onSearchKeyChanged,
  searchText
}: Props) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isUserLicensed, setIsUserLicensed] = useState<boolean>(false);
  const history = useHistory();

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === 'toggle-menu') {
      setMenuOpen(!menuOpen);
    }
  };

  const getStyle = useCallback(() => ((title === toTitleCase(GROUPS) || title === toTitleCase(USERS) || title === toTitleCase(TEAMS) || title === toTitleCase(TEMPLATES) || title === toTitleCase(SLA.SLA)) ? { height: height ?? 'calc(100vh - 110px)' } : { marginTop: '-1.25rem', height: height ?? 'calc(100vh - 150px)' }), [title]);

  useEffect(() => {
    (async () => setIsUserLicensed((await getCachedFeature(PRODUCT_LICENSE.AnalystAccess))))();
  }, []);

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          {title === toTitleCase(USERS) && (
            <Toolbar
              globalTheme={globalTheme}
              title={`${toTitleCase(SETTINGS)} > ${title}`}
              onInteraction={onToolbarInteraction}
              breadcrumbs={breadcrumbs}
              addNew
              addNewContent={
                <Button
                  icon={<SyncIcon />}
                  text
                  primary
                  disabled={!isUserLicensed}
                  content={UPDATE}
                  onClick={syncAllUsers}
                  style={{
                    color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme)
                  }}
                />
              }
            />
          )}
          {(title === toTitleCase(GROUPS) || title === toTitleCase(TEAMS) ||
            title === toTitleCase(TEMPLATES)) && (
              <Toolbar
                globalTheme={globalTheme}
                title={`${toTitleCase(SETTINGS)} > ${title}`}
                breadcrumbs={breadcrumbs}
                addNew
                onInteraction={onToolbarInteraction}
                addNewContent={
                  <Button
                    icon={<AddIcon />}
                    text
                    primary
                    content={addNewTitle}
                    disabled={!isUserLicensed}
                    onClick={() => {
                      if (isUserLicensed) {
                        if (onAddHandler) return onAddHandler();
                        const path = `/settings/${routeName}?id=0`;
                        history.push(path);
                      }
                    }}
                    style={{
                      color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme),
                      height: '52px'
                    }}
                  />
                }
              />
            )}
          {title === toTitleCase(TAGS.TAGS) && (
            <Toolbar
              globalTheme={globalTheme}
              title={`${toTitleCase(SETTINGS)} > ${title}`}
              breadcrumbs={breadcrumbs}
              addNew
              onInteraction={onToolbarInteraction}
              addNewContent={
                <Button
                  icon={<AddIcon />}
                  text
                  primary
                  content={t('knowledge.new-tag')}
                  disabled={!isUserLicensed}
                  onClick={() => {
                    onAddHandler && onAddHandler();
                  }}
                  style={{
                    color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme),
                    height: '52px'
                  }}
                />
              }
            />
          )}
          {title === toTitleCase(ACCESS_TOKEN.TOKEN) && (
            <Toolbar
              globalTheme={globalTheme}
              title={`${toTitleCase(SETTINGS)} > ${title}`}
              breadcrumbs={breadcrumbs}
              addNew
              onInteraction={onToolbarInteraction}
              addNewContent={
                <Button
                  icon={<AddIcon />}
                  text
                  primary
                  content={t('knowledge.new-token')}
                  disabled={!isUserLicensed}
                  onClick={() => {
                    onAddHandler && onAddHandler();
                  }}
                  style={{
                    color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme),
                    height: '52px'
                  }}
                />
              }
            />
          )}
          {(title === toTitleCase(AUTOMATION.AUTOMATION)) && (
            <Toolbar
              globalTheme={globalTheme}
              title={`${toTitleCase(SETTINGS)} > ${title}`}
              breadcrumbs={breadcrumbs}
              addNew
              onInteraction={onToolbarInteraction}
              addNewContent={
                <Button
                  icon={<AddIcon />}
                  text
                  primary
                  content={t('automation.new-automation')}
                  disabled={!isUserLicensed}
                  onClick={() => {
                    const path = `/settings/${routeName}?id=0`;
                    history.push(path);
                  }}
                  style={{
                    color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme),
                    height: '52px'
                  }}
                />
              }
            />
          )}
          {/* SLA Toolbar */}
          {title === toTitleCase(SLA.SLA) && (
            <Toolbar
              globalTheme={globalTheme}
              title={`${toTitleCase(SETTINGS)} > ${title}`}
              breadcrumbs={breadcrumbs}
              addNew
              onInteraction={onToolbarInteraction}
              addNewContent={
                <Button
                  icon={<AddIcon />}
                  text
                  primary
                  content={t('knowledge.new-sla')}
                  disabled={!isUserLicensed}
                  onClick={() => {
                    const path = `/settings/${routeName}?id=0`;
                    history.push(path);
                  }}
                  style={{
                    color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme),
                    height: '52px'
                  }}
                />
              }
            />
          )}

          {/* Main Section */}
          <Flex style={getStyle()}>
            <Flex.Item grow styles={{ paddingTop: '1.25rem', overflow: 'auto' }}>
              <div style={{ padding: 10 }}>
                {ifEmpty && (
                  <>

                    {rows.length === 0 && (
                      <EmptyData
                        headerText={ifEmpty.header}
                        subheaderText={ifEmpty.subHeader}
                        SVGIcon={<EmptySVG width={200} height={200} />}
                      />
                    )}

                    {rows.length > 0 && (
                      <TableWrapper
                        {...{
                          headers,
                          headerNotTitleCase,
                          rows,
                          routeName,
                          currentPage,
                          setCurrentPage
                        }}
                        onRowClickHandler={
                          onRowClickHandler && onRowClickHandler
                        }
                        searchText={searchText}
                        onSearchKeyChanged={onSearchKeyChanged}
                      />
                    )}
                  </>
                )}
              </div>
            </Flex.Item>
          </Flex>
        </>
      )}
    />
  );
};
