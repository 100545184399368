import React, { useState, useEffect } from 'react';
import Styles from '../styles/Lifecycle.module.css';
import { Flex, ThemePrepared, Alert, Text, Button, AddIcon } from '@fluentui/react-northstar';
import { TicketLifecyclePhaseAccordion } from './TicketLifecyclePhaseAccordion';
import { EmptyData } from '../EmptyData';
import { TicketApprovalCard } from '../EditTicketApprovals/TicketApprovalCard';
import { TicketTasksDetails } from '../TicketTasks/TicketTasksList/TicketTaskCard/TicketTasksDetails';
import AdditionalInfoIcon from '../../../../svg/additional-info-icon.svg';
import { platformService } from '../../services/platform.service';
import { TicketApprovalState, toastDefault, LIFECYCLE, ADMINISTRATORS, ANALYSTS, STATUS_GUID, LIFECYCLE_STATUS_GUID, TASK_STATUS_GUID } from '../../utils/constants';
import { toast } from 'react-toastify';
import { TicketTasksDialog } from '../TicketTasks/TicketTasksDialog/TicketTasksDialog';
import { TicketTasksListSkeleton } from '../TicketTasks/Skeletons/TicketTasksSkeleton';
import { PlatformUser } from '../../interfaces/platformuser.interface';
import { appState, useSetState} from '../../../AppState';
import { refershPlatformUserState, updateApproval } from '../../../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../../../svg/info-icon.svg';
import { ApprovalsDialog } from '../EditTicketApprovals/ApprovalsDialog';
import { TicketApprovalStateString } from '../../../lifecycle/utils/constants';

const initialTicketTaskDetails = {
  Title: null,
  StatusId: undefined,
  AssigneeId: undefined,
  TicketId: undefined,
  Assignee: undefined,
  Status: undefined,
  TeamId: undefined,
  Team: null,
  SupportGroupId: undefined,
  SupportGroup: null,
  TaskCollaborators: [],
  TicketLifecyclePhaseId: undefined
};

const initialTicketApprovalDetails = {
  Id: 0,
  AdditionalDetails: '',
  Title: '',
  IsRequiredByAll: false,
  Approvers: [],
  TicketApprovals: [],
  ApprovalState:TicketApprovalState.Pending
}

interface Props {
  phaseData: TicketLifecyclePhase;
  lifecycleStatus: LifecycleStatus;
  phaseIndex: number;
  activePhaseIndex: number;
  openPhaseDefault?: boolean;
  globalTheme: ThemePrepared<any>;
  ticketData: any;
  statuses: TaskStatus[];
  analysts: PlatformUser[];
  refreshApprovalsList: (approval: Approval, approverId: number, status: TicketApprovalState, UpdatedStatus: TicketApprovalState) => void;
  reloadPhases: () => Promise<void>;
  enableTaskEdit?: boolean;
  enableAppovalEdit?: boolean;
  setLoading: (data: boolean) => void;
}

export const TicketLifecyclePhaseContainer = ({
  phaseData,
  phaseData: { Id, Name, Tasks, Approvals, TransitionMessage, PowerAutomateTasks },
  lifecycleStatus,
  phaseIndex,
  activePhaseIndex,
  openPhaseDefault,
  globalTheme,
  ticketData,
  statuses,
  analysts,
  refreshApprovalsList,
  reloadPhases,
  enableTaskEdit,
  enableAppovalEdit,
  setLoading
}: Props) => {

  const api = new platformService();
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(openPhaseDefault);
  const [isApprovalLoading, setIsApprovalLoading] = useState<boolean>(false);
  const [toggleExpandMessage, setToggleExpandMessage] = useState<boolean>(TransitionMessage?.length > 50);
  
  const [phaseTasksList, setPhaseTasksList] = useState<TicketTask[]>(Tasks);
  const [phasePowerAutomateTasksList] = useState<TicketTask[]>(PowerAutomateTasks);
  const [ticketTaskDetails, setTicketTaskDetails] = useState<TicketTask>(initialTicketTaskDetails);
  const [openTicketTasksDialog, setOpenTicketTasksDialog] = useState<boolean>(false);
  const [isTaskLoading, setIsTaskLoading] = useState<boolean>(false);
  const [showUpdateBtn, setShowUpdateBtn] = useState<boolean>(false);

  const [openApprovalDialog, setOpenApprovalDialog] = useState<boolean>(false);
  const [approversList, setApproversList] = useState<PlatformUser[]>([]);
  const [ticketApprovalDetails, setTicketApprovalDetails] = useState<Approval>();
  const [showUpdateApprovalBtn, setShowUpdateApprovalBtn] = useState<boolean>(false);
  const [enableTransitionButton, setEnableTransitionButton] = useState<boolean>(false);
  const [showAddTaskButton, setShowAddTaskButton] = useState<boolean>(false);
  const [showAddApprovalButton, setShowAddApprovalButton] = useState<boolean>(false);

  const isActivieLifecycle = (lifecycleStatus.Guid != LIFECYCLE_STATUS_GUID.COMPLETED && lifecycleStatus.Guid != LIFECYCLE_STATUS_GUID.FAILED);

  const setAppState = useSetState();
  const currentState = appState();
  const {t} = useTranslation();

  const updateApprovalStatus = async (approval: Approval, approverId: number, status: TicketApprovalState) => {
    try {
      setIsApprovalLoading(true);

      const isEditAllowed = await isEditPhaseAllowed();

      if(!isEditAllowed){
        toast.error(t('ticket-details.edit-ticket-form.approval.error.phase-not-active'), toastDefault);
        await reloadPhases();
        setIsApprovalLoading(false);
        return;
      };
      const ticketLifecyclePhases = await api.getTicketLifecyclePhases(`?$filter=Id eq ${approval.TicketLifecyclePhaseId}`);
      const isCurrentPhase = ticketLifecyclePhases[0]?.IsCurrent ?? true;
      if (!isCurrentPhase) {
        toast.error(t('ticket-details.edit-ticket-form.approval.error.phase-not-active'), toastDefault);
        await reloadPhases();
        setIsApprovalLoading(false);
        return;
      }
      const result = await updateApproval(api, approval, approverId, status, t);
      if (result.IsError) {
        toast.error(result.Message, toastDefault);
        setIsApprovalLoading(false);
      }
      else {
        refreshApprovalsList(approval, approverId, status, result.UpdatedStatus);
        toast.success(result.Message, toastDefault);

        //delay reloading phase data on approval rejection to make time for lifecycle service to finish updating lifecycle
        if(result.UpdatedStatus == TicketApprovalState.Rejected){
          setShowAddApprovalButton(false);
          setShowAddTaskButton(false);
          setTimeout(async()=>{
            await reloadPhases();
            setIsApprovalLoading(false);
          },2000)
        }else{
          setIsApprovalLoading(false);
        }
      }

    } catch (e) {
      toast.error(t('ticket-details.edit-ticket-form.approval.error.update'), toastDefault);
      console.error(e);
    }
  }

  const onTaskClick = (taskDetails: TicketTask) => {
    setTicketTaskDetails({ ...taskDetails });
    setShowUpdateBtn(true);
    setOpenTicketTasksDialog(true);
  };

  const onModalClose =  async() => {
    setOpenTicketTasksDialog(false);
    setShowUpdateBtn(false);
    setTicketTaskDetails({ ...initialTicketTaskDetails });
  };

  const getAllTicketTasksList = async (id: number) => {
    setIsTaskLoading(true);
    try {
      let params: string = `?$filter=TicketId eq ${id}&$expand=Status,Assignee,TicketLifecyclePhase,Team,SupportGroup,TaskCollaborators`;
      const list = await api.getTicketTasks(params);
      const tasksList: TicketTask[] = list;
      const phaseTasks = tasksList.filter(l => l.TicketLifecyclePhaseId == phaseData.Id && !l.IsPowerAutomateTask);
      setPhaseTasksList(phaseTasks);

      if(phaseTasks.some((item: any) => item.Status.Guid == TASK_STATUS_GUID.FAILED)){
        setShowAddApprovalButton(false);
        setShowAddTaskButton(false);
        
        //delay reloading phase data on lifecycle task to make time for lifecycle service to finish updating lifecycle
        setTimeout(async()=>{
          await reloadPhases();
          setIsTaskLoading(false);
        },2000);
      }else{
        await reloadPhases();
        setIsTaskLoading(false);
      }
    } catch (error) {
      console.log('Error in tickets tasks:', error);
      setIsTaskLoading(false);
    }
    
  };

  const addTicketApprovals = async (data: Approval) => {
    try {
      setIsApprovalLoading(true);

      const isEditAllowed = await isEditPhaseAllowed();

      if(!isEditAllowed){
        toast.error(t('ticket-details.edit-ticket-form.approval.error.phase-not-active'), toastDefault);
        await reloadPhases();
        setIsApprovalLoading(false);
        return;
      };

      if (approversList != null) {
        const results = await Promise.all(approversList.map(async user => api.getOrCreatePlatformUser(user)));
        await Promise.all(approversList.map(async user => refershPlatformUserState(user, currentState, setAppState)));
        
        data.ApprovalState = TicketApprovalState.Pending;
        results.forEach(result => {

          data.Approvers.push({
            ApprovalId: data.Id,
            ApproverId: result.Id,
            HasApproved: 3,
            Id: 0
          });
        });
      }
      data.TicketApprovals.push({ Id: 0, TicketId: ticketData.Id, ApprovalId: data.Id })
      data.TicketLifecyclePhaseId = phaseData.Id;
      let savedApproval = await api.addTicketApproval(data);
      if (savedApproval != null) {
        await getTicketApprovals();
        toast.success(t('ticket-details.edit-ticket-form.approval.success.created'), toastDefault);
      }
    } catch (e) {
      console.error(e);
      toast.error(t('ticket-details.edit-ticket-form.approval.error.update'), toastDefault);
      await getTicketApprovals();
    }
    data = initialTicketApprovalDetails;
    setApproversList([]);
    setIsApprovalLoading(false);
    setTicketApprovalDetails({...initialTicketApprovalDetails})
  }

  const getTicketApprovals = async () => {
    try {
      let approvals: [] = [];
      approvals = await api.getTicketApprovals(ticketData.Id);
      const hasPendingApprovals: boolean = approvals.some((item: any) => item.Approval.ApprovalState == TicketApprovalStateString.Pending);
      setAppState(prevState => ({
        ...prevState,
        lifecycleApprovals: approvals,
        hasPendingApprovals: hasPendingApprovals
      }));
      await reloadPhases();
    } catch(e) {
      console.error(e);
    }
  }

  const onApprovalClick = (approvalDetail: Approval) => {
    setTicketApprovalDetails({...approvalDetail});
    setShowUpdateApprovalBtn(true);
    setOpenApprovalDialog(true);
  };

  const updateTicketApprovals = async(approval: Approval)=>{
    try {
      setIsApprovalLoading(true);
      
      const isEditAllowed = await isEditPhaseAllowed();

      if(!isEditAllowed){
        toast.error(t('ticket-details.edit-ticket-form.approval.error.phase-not-active'), toastDefault);
        await reloadPhases();
        setIsApprovalLoading(false);
        return;
      };
      
      const lastUpdatedApproval = await api.getApproval(approval.Id);
      const isApprovalUpdateAllowed = lastUpdatedApproval[0].ApprovalState === TicketApprovalState.Pending;
        
      if(!isApprovalUpdateAllowed){
        const errorMessage = lastUpdatedApproval[0].ApprovalState === TicketApprovalState.Rejected ? 
        t('ticket-details.edit-ticket-form.approval.error.update-already-rejected') :
        t('ticket-details.edit-ticket-form.approval.error.update-already-approved')

        toast.error(errorMessage, toastDefault);
         await reloadPhases();
         setIsApprovalLoading(false);
         return;
      }

      let approverIds = [];
      if (approversList != null) {
         const results = await Promise.all(approversList.map(async user => api.getOrCreatePlatformUser(user)))
         approverIds = results.map(item => item.Id);
      }

      const updatedApprovalData = {
        Title: approval.Title,
        AdditionalDetails: approval.AdditionalDetails,
        IsRequiredByAll: approval.IsRequiredByAll
      };

      let updatedApproval = await api.updateTicketApprovalApprovers(approval.Id, approverIds, JSON.stringify(updatedApprovalData));

      if (updatedApproval != null) {
          await getTicketApprovals();
          toast.success(t('ticket-details.edit-ticket-form.approval.success.updated'), toastDefault);
      }
    } catch (e) {
      console.error(e);
      toast.error(t('ticket-details.edit-ticket-form.approval.error.create'), toastDefault);
      await getTicketApprovals();
      setApproversList([]);
    }
    setIsApprovalLoading(false);
    setTicketApprovalDetails({...initialTicketApprovalDetails});
  }

  const renderAddTaskButton = ()=>{
    return <Flex.Item>
                <Button className="mt-5"
                  icon={<AddIcon 
                      styles={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} 
                      />}
                  text
                  primary
                  content={<span 
                      style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}
                      >{LIFECYCLE.ADD_TASK}</span>}
                    onClick={() => {
                      const defaultTaskStatus = statuses.find(item => item.IsDefault)
                      setTicketTaskDetails({...ticketTaskDetails, TicketLifecyclePhaseId: phaseData.Id, Status: defaultTaskStatus, StatusId: defaultTaskStatus.Id});
                      setOpenTicketTasksDialog(true);
                    }}
                  />
              </Flex.Item>
  }

  const renderAddApprovalButton = ()=>{
    return <Flex.Item>
              <Button className="mt-5"
                icon={<AddIcon 
                    styles={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} 
                    />}
                text
                primary
                content={<span 
                    style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}
                    >{LIFECYCLE.ADD_APPROVAL}</span>}
                  onClick={() => {
                    setShowUpdateApprovalBtn(false);
                    setOpenApprovalDialog(true);
                  }}
                />
            </Flex.Item>
  }

  const isEditPhaseAllowed = async() =>{
    const ticketLifecyclePhases = await api.getTicketLifecyclePhases(`?$filter=Id eq ${phaseData.Id}&$expand=TicketLifecycle($expand=Status)`);
    
    const isCurrentPhase = ticketLifecyclePhases[0]?.IsCurrent ?? true;
    const isFuturePhase = ticketLifecyclePhases[0].Order > activePhaseIndex;
    const isActivieLifecycle = (ticketLifecyclePhases[0].TicketLifecycle.Status.Guid != LIFECYCLE_STATUS_GUID.COMPLETED 
      && ticketLifecyclePhases[0].TicketLifecycle.Status.Guid != LIFECYCLE_STATUS_GUID.FAILED);

    return ((isCurrentPhase && isActivieLifecycle) || isFuturePhase);
  }

  useEffect(() => {
    if (isAccordionOpen){
      const divElement = document.getElementById(`phase-container-${phaseData.Order}`);
      if(divElement)
        divElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [isAccordionOpen]);

  useEffect(()=>{
    const hasTransitionAccess = currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS) 
    const enableTransition = (ticketData.Status && (ticketData.Status.Guid == STATUS_GUID.CLOSED || ticketData.Status.Guid == STATUS_GUID.RESOLVED)) ? true : !phaseData.IsCurrent;
    setEnableTransitionButton(hasTransitionAccess && enableTransition);

    const hasAddTaskAccess = currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS) ;
    const enableAddTask =((phaseData.IsCurrent && isActivieLifecycle) || phaseIndex > activePhaseIndex) && ticketData.Status.Guid != STATUS_GUID.CLOSED ;
    setShowAddTaskButton(hasAddTaskAccess && enableAddTask);

    const hasAddApprovalAccess = currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS) ;
    const enableAddApproval =((phaseData.IsCurrent && isActivieLifecycle) || phaseIndex > activePhaseIndex) && ticketData.Status.Guid != STATUS_GUID.CLOSED ;
    setShowAddApprovalButton(hasAddApprovalAccess && enableAddApproval);

  },[phaseData.IsCurrent, ticketData.Status]);
  
  return (
    <>
      <TicketLifecyclePhaseAccordion
        isAccordionOpen={isAccordionOpen}
        setIsAccordionOpen={setIsAccordionOpen}
        globalTheme={globalTheme}
        phaseData={phaseData}
        lifecycleStatus={lifecycleStatus}
        activePhaseIndex={activePhaseIndex}
        reloadPhases = {reloadPhases}
        setLoading = {setLoading}
        enableTransitionButton = {enableTransitionButton}
        ticketData = {ticketData}
      />
      {isAccordionOpen && (
          <>

          {((TransitionMessage?.length ?? 0) > 0) && (
              <Alert className="mt-2 pb-2 pt-2" warning
                header={<Flex styles={{height: '100%', alignItems: 'center'}}>
                  <InfoIcon
                    width={16}
                    height={16}
                    className={`inline-block`}
                  /></Flex>}
                content={
                <>
                  <Text weight="semibold" content={t('common.transition-reason')} />
                  <Text size='small' content={<>
                        {toggleExpandMessage ? TransitionMessage.substring(0, 50) : TransitionMessage}                                              
                        {TransitionMessage?.length > 50 &&
                        <>{toggleExpandMessage ? "..." : ""} <span className={`cursor-pointer`} style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}
                        onClick={() => {setToggleExpandMessage(!toggleExpandMessage)}}
                        >{toggleExpandMessage ? LIFECYCLE.SEE_MORE : LIFECYCLE.SEE_LESS}</span></>}
                    </>} />
                </>
              }
              />
          )}


          {(phaseTasksList.length <= 0 && showAddTaskButton) && (
            <Flex space={`between`} vAlign={'center'}>
              <Flex.Item><h2 className="mt-5" style={{marginBottom: '0px'}}><b>{t('ticket-details.edit-ticket-form.task.title')}</b></h2></Flex.Item>
              {(showAddTaskButton) && renderAddTaskButton()}
            </Flex>)}
          {(phaseTasksList.length > 0) && (
            <>
            <Flex space={`between`} vAlign={'center'}>
              <Flex.Item><h2 className="mt-5" style={{marginBottom: '0px'}}><b>{t('ticket-details.edit-ticket-form.task.title')}</b></h2></Flex.Item>
              {(showAddTaskButton) && renderAddTaskButton()}
            </Flex>
            {isTaskLoading && (<TicketTasksListSkeleton/>)}
            {!isTaskLoading && (

              <div className={`grid grid-cols-1 gap-4 lg:grid-cols-2 phaseitem-container`}>
                
                  {phaseTasksList.map((task) => (
                      <div key={task.Id} className={`mt-4 ${(((phaseData.IsCurrent || phaseIndex > activePhaseIndex) && (enableTaskEdit)) ?? true) ? 'cursor-pointer' : ''}`} 
                    onClick={(e) => { 
                      if(((phaseData.IsCurrent || phaseIndex > activePhaseIndex) && (enableTaskEdit)) ?? true)
                        onTaskClick(task) 
                      else
                        e.stopPropagation();
                    }}>
                      <TicketTasksDetails ticketTask={task}/>
                    </div>
                    
                  ))}
              </div>
            )} 
            </>
          )}

          {(Approvals.length <= 0 && showAddApprovalButton) && (<Flex space={`between`} vAlign={'center'}>
              <Flex.Item><h2 className="mt-5" style={{marginBottom: '0px'}}><b>{t('ticket-details.edit-ticket-form.approval.title')}</b></h2></Flex.Item>
              {(showAddApprovalButton) && renderAddApprovalButton()}
            </Flex>)}
          {(Approvals.length > 0) && (
            <>
            <Flex space={`between`} vAlign={'center'}>
              <Flex.Item><h2 className="mt-5" style={{marginBottom: '0px'}}><b>{t('ticket-details.edit-ticket-form.approval.title')}</b></h2></Flex.Item>
              {(showAddApprovalButton) && renderAddApprovalButton()}
            </Flex>
            {isApprovalLoading && (<TicketTasksListSkeleton/>)}
            {!isApprovalLoading && (
              <div className={`grid grid-cols-1 gap-4 lg:grid-cols-2 phaseitem-container mt-2`}>
                  {Approvals.map((approval) => (
                    <div key={approval.Approval.Id} className={`mt-4 ${((( (phaseData.IsCurrent && isActivieLifecycle) || phaseIndex > activePhaseIndex) && (enableAppovalEdit) && (approval.Approval.ApprovalState == TicketApprovalState.Pending))  ?? true) ? 'cursor-pointer' : ''}`} 
                    onClick={(e) => { 
                      if((((phaseData.IsCurrent && isActivieLifecycle) || phaseIndex > activePhaseIndex)  && (enableAppovalEdit) && (approval.Approval.ApprovalState == TicketApprovalState.Pending)) ?? true)
                        onApprovalClick(approval.Approval) 
                      else
                        e.stopPropagation();
                    }}>
                      <TicketApprovalCard 
                        approval={approval.Approval} 
                        currentUserId={currentState.currentUserId}
                        updateApprovalStatus={updateApprovalStatus} 
                        isTicketClosed={ticketData.IsClosed}
                        isLifecycleEnabled={true} 
                        activePhaseIndex={activePhaseIndex}
                        phaseIndex = {phaseIndex}
                        enableApprovalButton = {enableTaskEdit}/>
                    </div>
                  ))}
              </div>
            )}  
          </>
          )}

        {(phasePowerAutomateTasksList.length > 0) && (
            <>
            <h2 className="mt-5"><b>{LIFECYCLE.POWER_AUTOMATE_TASKS}</b></h2>
            {isTaskLoading && (<TicketTasksListSkeleton/>)}
            {!isTaskLoading && (
              <div className={`grid grid-cols-1 gap-4 lg:grid-cols-2 phaseitem-container`}>
                  {phasePowerAutomateTasksList.map((task) => (
                    <TicketTasksDetails key={task.Id} ticketTask={task}/>
                  ))}
              </div>
            )} 
            </>
          )}

          <TicketTasksDialog
            open={openTicketTasksDialog}
            close={() => {
              onModalClose();
            }}
            statuses={statuses}
            analysts={analysts}
            ticketId={ticketData.Id}
            ticketTitle={ticketData.Title}
            getAllTicketList={getAllTicketTasksList}
            setIsLoading={setIsTaskLoading}
            ticketTaskDetails={ticketTaskDetails}
            showUpdateBtn={showUpdateBtn}
            setShowUpdateBtn={(value: boolean) => setShowUpdateBtn(value)}
            isLifecycle={true}
            reloadPhases={reloadPhases}
            isCurrentPhase = {phaseData.IsCurrent}
            activePhaseIndex={activePhaseIndex}
            phaseId = {phaseData.Id}
          />

          <ApprovalsDialog
            open={openApprovalDialog}
            onCancel={() => {
              setOpenApprovalDialog(false); 
              setTicketApprovalDetails({...initialTicketApprovalDetails});
            }}
            addTicketApprovals={addTicketApprovals}
            approversList={approversList}
            setApproversList={setApproversList}
            existingTicketApprovalData = {ticketApprovalDetails}
            isUpdateButton = {showUpdateApprovalBtn}
            updateTicketApproval = {updateTicketApprovals}
          />
          </>
      )}
    </>
  );
};

