import React, { useCallback, useState } from 'react';
import { Button, Flex, ProviderConsumer } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  SETTINGS,
  SETTINGS_PAGES,
  ENDPOINT_SECURITY_MAPPINGS,
  LIFECYCLE,
  PRODUCT_LICENSE
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { Toolbar } from '../shared/components/Toolbar';
import ConfigCSS from '../config/config.module.css';
import { UpgradeNeeded } from '../shared/components/UpgradeNeeded';
import useCurrentPage from '../kb/useCurrentPage';
import { Lifecycle } from './utils/typings';
import { LifeCycleToolbar } from './LifeCycleToolbar';
import {LifecycleTableView} from './views/LifeCycleTableView';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { useTranslation } from 'react-i18next';

export const LifecycleTable = () => {
  const { t } = useTranslation();
  const api = new platformService();
  const [data, setData] = useState<Lifecycle[]>([]);
  const [apiStatus, setApiStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );
  const {currentPage, setCurrentPage} = useCurrentPage();
  const [isUserLicensed, setIsUserLicensed] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: LIFECYCLE.GETTING_ALL_LIFECYCLE });
    
    let isUserLicensed = await getCachedFeature(PRODUCT_LICENSE.AnalystAccess);
    setIsUserLicensed(isUserLicensed);

    const check = ENDPOINT_SECURITY_MAPPINGS.find(x => x.key == SETTINGS_PAGES.LIFECYCLE);
    if (check && JSON.parse(sessionStorage.getItem('my_application_roles') || '[]').filter(x => x.EndpointPath == check.path && x.Permissions.includes(check.method)).length <= 0) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: 403
      });
      return;
    }
    try {
      const expandTasksQuery = "Tasks($count=true;$top=0)";
      const expandApprovalsQuery = "Approvals($count=true;$top=0)";
      const expandPowerAutomateTasksQuery = "PowerAutomateTasks($count=true;$top=0)";

      const {
        data: { value }
      } = await api.getLifecycles(`?$orderby=CreatedDate desc&$expand=Phases($expand=Transitions,${expandTasksQuery},${expandApprovalsQuery},${expandPowerAutomateTasksQuery})`);

      setData(value);
      setApiStatus({ ...apiStatus, status: DONE });
    } catch (err) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err["response"]["status"]
      });
    }
  }, []);

  const loadPage = function() {
    fetchData();
  }

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: `/${SETTINGS}`
      },
      {
        title: LIFECYCLE.NAME,
        link: ''
      }
    ]
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401 || apiStatus.errCode === 403
          ? t('common.unauthorized-access')
          : t('common.something-went-wrong'),
      desc:  t('common.please-contact-your-administrator')
    }
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{t('lifecycle.lifecycle-tikit')}</title>
      </Helmet>
      {
        apiStatus.status === LOADING ? (
          <LoadingScreen message={apiStatus.msg} />
        ) : 
        (<>
          {apiStatus.status === ERROR && (
          <>
            {apiStatus.errCode != 402 && (
              <ProviderConsumer render={(globalTheme) => (
              <>
                <Toolbar globalTheme={globalTheme} title={LIFECYCLE.TOOLBAR_TITLE} hideSave={true} breadcrumbs={navs}/>
                <Flex className={ConfigCSS.configBody}>
                  <Flex.Item grow>
                    <>
                      <Communication {...errConfig} />
                      {apiStatus.errCode === 401 && (<Button content={t('common.refresh-session')} primary onClick={function(_e) { Providers.globalProvider.login(); }} />)}
                    </>
                  </Flex.Item>
                </Flex>
              </>)} />
            )}
            {apiStatus.errCode === 402 && (
              <UpgradeNeeded
                toolbarText={`${LIFECYCLE.BREADCRUMB_SETTINGS} > ${LIFECYCLE.NAME}`}
                breadcrumbs={navs}
                headerText={LIFECYCLE.NO_ACCESS_TO_CREATE}
                subheaderText={LIFECYCLE.UPGRADE_PLAN}
              />
            )}
          </>
          )} 
          {apiStatus.status != ERROR && (
            <ProviderConsumer render={(globalTheme) => (
              <>
                <LifeCycleToolbar
                  title={LIFECYCLE.NAME}
                  addNewTitle={`${LIFECYCLE.NEW_LIFECYCLE}`}
                  routeName={'lifecycle'}
                  breadcrumbs={navs}
                  isUserLicensed={isUserLicensed}
                />
                <div>
                  <div className={`${ConfigCSS['lifecycleListingScreen']}`}>
                    <LifecycleTableView rows={data} currentPage={currentPage} setCurrentPage={setCurrentPage} fetchData={fetchData} />
                  </div>
                </div>
              </> 
            )}/>
          )}
        </>
        )
      }
    </CheckLogin>
  )
};
